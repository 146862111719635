import React from "react"
import { Card, Link } from "@hdir/ui"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"
import FontAwesomeIconWrapper from "../../../react4xp/shared/FontAwesomeIconWrapper/FontAwesomeIconWrapper"

import './lenkeboks.scss'
import type { lenkeBoksPropsType, rowItemType, cardItemType, CardItemComponentProps } from './type.d'

function LenkeBoks(props: Readonly<lenkeBoksPropsType>) {

    const {
        title,
        ingress,
        headerIconUrl,
        rowItems,
        bottomLinkElement
        } = props

    return (
        <div className="w-full py-md tablet:py-lg desktop:py-xl my-0">
            <div className="flex items-center">
                {headerIconUrl && <img className="w-7 h-7 mr-xs" src={headerIconUrl} />}
                {title && <h2 className="text-xxl font-bold break-normal hyphens-auto">{title}</h2>}
            </div>
            {ingress && <div className="my-md text-md break-normal hyphens-auto">{ingress}</div>}

            {rowItems.map((rowItem: rowItemType) =>
                <GridComponent
                    title={title}
                    itemsRow={rowItem.itemsRow}
                    numberOfColumnsRow={rowItem.itemsRow.length}
                    showImageRow={rowItem.showImageRow}
                    showIngressRow={rowItem.showIngressRow}
                    imageWidthRow={rowItem.imageWidthRow}
                />
            )}

            {bottomLinkElement?.text &&
                <Link variant="button-secondary" className="py-md mt-md" size="small" href={bottomLinkElement.link}>
                    {bottomLinkElement.text}
                </Link>
            }
        </div>
    )
}

function GridComponent({ title, itemsRow, numberOfColumnsRow, showImageRow, showIngressRow, imageWidthRow }) {
    const gridClasses = {
        1: 'grid-cols-1',
        2: 'grid-cols-1 tablet:grid-cols-2',
        3: 'grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3',
    }
    return (
        <div className={`grid-container grid ${gridClasses[numberOfColumnsRow]} gap-sm`}>
            {itemsRow &&
                itemsRow.map((item: cardItemType) =>
                    <CardItem title={title} item={item} showImageRow={showImageRow} showIngressRow={showIngressRow} imageWidthRow={imageWidthRow} />
                )
            }
        </div>
    )
}

function CardItem({ title, item, showImageRow, showIngressRow, imageWidthRow }: CardItemComponentProps) {

    const imageFitClass = (imageWidthRow == 'full') ? "object-cover" : "object-contain"
    const headingPaddingClass = (showImageRow && item.itemImage) ? "py-sm" : "pb-sm"

    return (
        <Link className="lenkeboks-card !w-auto rounded-sm shadow-none my-xs p-sm tablet:p-md"
            variant="card" href={item.linkElement.link} target={item.linkElement.openInNewTab ? '_blank' : ''}>
            {(showImageRow && item.itemImage) &&
                <Card.Banner position="top" >
                    <img className={`overflow-hidden h-52 rounded-sm !w-full ${imageFitClass}`} src={item.itemImage} alt={item.itemImage} />
                </Card.Banner>
            }

            <div className="w-full h-full flex">
                <div className="p-sm tablet:p-0 break-normal hyphens-auto">
                    {item.itemTitle && (title && title != '')
                        ? <h3 className={`text-xl font-semibold break-normal hyphens-auto ${headingPaddingClass}`}>{item.itemTitle}</h3>
                        : <h2 className={`text-xxl font-semibold break-normal hyphens-auto ${headingPaddingClass}`}>{item.itemTitle}</h2>
                    }
                    {showIngressRow && <p className="font-normal text-md">{item.itemText}</p>}
                </div>
                <FontAwesomeIconWrapper icon={faArrowRight} style={{ color: "var(--design-color-icon-action-default)" }} size="lg"
                        className={`h-5 w-5 ml-sm pointer-events-none self-end`} />
            </div>
        </Link>
    )
}

export default (props: Readonly<lenkeBoksPropsType>) => <LenkeBoks {...props} />
